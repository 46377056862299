import { Component, OnInit, ViewChild } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { CommonService } from 'src/app/services/common.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject, Subscription, interval } from 'rxjs';
// import { IonSlides } from '@ionic/angular';
import { JsonPipe, DatePipe } from '@angular/common';
import { NgxSmartModalService } from 'ngx-smart-modal';

// declare function openChat(username: string, userEmail: string, teamleaderGUID: string, documentGUID: string):any;

@Component({
  selector: 'app-need-help',
  templateUrl: './need-help.component.html',
  styleUrls: ['./need-help.component.scss'],
  providers: [
    DatePipe,
  ],
})
export class NeedHelpComponent implements OnInit {


  defaultHelpDetails = {
    "Email":"info@rechtdirect.nl",
    "HelpText":"Neem contact met ons op!",
    "Phone":"085-0081180"
  };

  defaultHelpImage = "";

  // @ViewChild('IonSlides', { static: true }) slider: IonSlides;

  help: any = {};
  helpImageList: Array<any> = [];
  helpImageArray: Array<any> = [];
  interval: any;
  count: number = 0;
  // imageSubscription: Subscription;
  // source: any;

  constructor(private _apiService: ApiService,
    public _commonService: CommonService,
    private route: ActivatedRoute,
    private router: Router,
    private datePipe: DatePipe,
  public ngxSmartModalService: NgxSmartModalService) {
    // this.timeOut();
  }


  slideOptsOne: {
    initialSlide: 0,
    slidesPerView: 1,
    autoplay: true,
  }

  ngOnInit() {
    // if (!localStorage.authenticationKey && localStorage.authenticationKey == undefined) {
    //   this._commonService.helpDetail = localStorage.helpDetail && localStorage.helpDetail != "undefined"? JSON.parse(localStorage.helpDetail) : this.defaultHelpDetails;
    //   this._commonService.helpImage = localStorage.currentImage && localStorage.currentImage != "undefined"? JSON.parse(localStorage.currentImage) : null;



    // } else if (localStorage.authenticationKey) {

    // }
      if (!localStorage.authenticationKey && localStorage.authenticationKey == undefined) {
      this.getHelpDetailWithoutLogin();
      }else{
        this.getHelpDetail();
      }
    // let source = interval(3600000);
    // this.interval = setTimeout(() => {

    // }, 60000);
    // this.source = interval(60000);
  }

  // slidesDidLoad(slides: IonSlides) {
  //   // slides.getActiveIndex().then(aa => {
  //   //  var i = aa;
  //   //   this.interval = setTimeout(() => {
  //   //     i++;
  //   //           slides.slideTo(i);
  //   //   }, 5000);
  //   // })
  //   slides.startAutoplay();
  // }

  async getHelpDetail() {
    if(localStorage.helpDetail && localStorage.helpDetail != 'undefined' && localStorage.helpDetail != '' && localStorage.helpDetail != 'null'){
      this._commonService.helpDetail = JSON.parse(localStorage.helpDetail);
      await this.getCurrentImage();
    }else{
      try {
        let res = await this._apiService.getHelpDetail();
        if (res.body) {
          this._commonService.helpDetail = res.body;
          localStorage.setItem('helpDetail', JSON.stringify(res.body));
        }
        await this.getCurrentImage();
        await this.getCurrentHeaderImage()
        this._commonService.log(res.body, this._commonService.LOG);
        this._commonService.dismiss();

      } catch (error) {
        this._commonService.dismiss();
        this._commonService.log(error, this._commonService.ERROR)
      }
    }
  }

  async getCurrentImage() {
    if(localStorage.currentImage && localStorage.currentImage != 'undefined' && localStorage.currentImage != '' && localStorage.currentImage != 'null'){
      this._commonService.helpImage = JSON.parse(localStorage.currentImage)
    }else if(localStorage.currentImage == ''){
      this._commonService.helpImage = ''
    }
    else{
      try {
        let res = await this._apiService.getCurrentHelpImage();
        if(res.body){
          let helpImage = [];
          let image = res.body;
            let ext = image.Name.split('.').pop();
            let im = "data:image/" + ext + ";base64," + image.Data;

            let value = {
              Name: image.Name,
              Data: image.Data,
              image: im,
            }
            helpImage.push(value);
            localStorage.setItem('currentImage', JSON.stringify(im));
            this._commonService.helpImage = helpImage[0].image;
        } else {
          localStorage.setItem('currentImage', "");
          this._commonService.helpImage = "";
        }

      } catch (error) {
        this._commonService.dismiss();
        this._commonService.log(error, this._commonService.ERROR)
      }
    }
  }



  async getCurrentHeaderImage() {
    if(localStorage.currentHeaderImage && localStorage.currentHeaderImage != 'undefined' && localStorage.currentHeaderImage != '' && localStorage.currentHeaderImage != 'null'){
      this._commonService.headerImage = JSON.parse(localStorage.currentHeaderImage)
    }else if(localStorage.currentHeaderImage == ''){
      this._commonService.headerImage = ''
    }
    else{
      try {
        let res = await this._apiService.getCurrentHeaderImage();
        if(res.body){
          let headerImage = [];
          let image = res.body;
            let ext = image.Name.split('.').pop();
            let im = "data:image/" + ext + ";base64," + image.Data;

            let value = {
              Name: image.Name,
              Data: image.Data,
              image: im,
            }
            headerImage.push(value);
            localStorage.setItem('currentHeaderImage', JSON.stringify(im));
            this._commonService.headerImage = headerImage[0].image;
        } else {
          localStorage.setItem('currentHeaderImage', "");
          this._commonService.headerImage = "";
        }

      } catch (error) {
        this._commonService.dismiss();
        this._commonService.log(error, this._commonService.ERROR)
      }
    }
  }

  async getHelpDetailWithoutLogin(){
    try {
      // this._commonService.presentLoading()
      // let res = await this._apiService.getCurrentHelpImage();
      // if(res.body){
      //   let helpImage = [];
      //   let image = res.body;
      //     let ext = image.Name.split('.').pop();
      //     let im = "data:image/" + ext + ";base64," + image.Data;

      //     let value = {
      //       Name: image.Name,
      //       Data: image.Data,
      //       image: im,
      //     }
      //     helpImage.push(value);
      //     localStorage.setItem('currentImage', JSON.stringify(im));
      //     this._commonService.helpImage = helpImage[0].image;
      // } else {
      //   localStorage.setItem('currentImage', "");
      //   this._commonService.helpImage = "";
      // }
      // let response = await this._apiService.getHelpDetail();
      // if (response.body) {
      //   this._commonService.helpDetail = response.body;
      //   localStorage.setItem('helpDetail', JSON.stringify(response.body));
      // }

      // let respo = await this._apiService.getCurrentHeaderImage();
      // if(respo.body){
      //   let headerImage = [];
      //   let image = respo.body;
      //     let ext = image.Name.split('.').pop();
      //     let im = "data:image/" + ext + ";base64," + image.Data;

      //     let value = {
      //       Name: image.Name,
      //       Data: image.Data,
      //       image: im,
      //     }
      //     headerImage.push(value);
      //     localStorage.setItem('currentHeaderImage', JSON.stringify(im));
      //     this._commonService.headerImage = headerImage[0].image;
      // } else {
      //   localStorage.setItem('currentHeaderImage', "");
      //   this._commonService.headerImage = "";
      // }
      this._commonService.presentLoading()
      await this.getHelpDetail()
      await this.getCurrentHeaderImage()
      await this.getCurrentImage()

      this._commonService.dismiss()
    } catch (error) {
      this._commonService.dismiss();
      this._commonService.log(error, this._commonService.ERROR)
    }
  }



  hyperlinks(type) {
    let url;
    if(type == "email") {
      url = 'mailto:'+this._commonService.helpDetail.Email;
    window.open(url, '_blank');
    } else if(type == "phone") {
      url = 'tel:+'+this._commonService.helpDetail.Phone;
      window.open(url, '_blank');
    } else if(type != "email" && type != "phone")  {
      url = type;
      window.open(url, '_blank');
    }
  }

askQuestion(event){
    //only allows the inner button to be pressed.
    event.stopPropagation();
    this._commonService.openFromPopover = true;
    this.ngxSmartModalService.getModal('askQuestionModal').open();
  
}

  // startChat(){
  //   var email = localStorage.userName ? localStorage.userName : "Unknown";
  //   var firstName = localStorage.firstName ? localStorage.firstName : "Unknown";
  //   var teamleaderGUID = localStorage.TeamLeaderGuid ?  localStorage.TeamLeaderGuid : 'Unknown';
  //   openChat(firstName, email,teamleaderGUID, undefined);
  // }

  //   timeOut(){
  //     let time;
  //     if(localStorage.helpImageChangeInterval){
  //       time = JSON.parse(localStorage.helpImageChangeInterval);
  //     } else {
  //       time = 86400000;
  //     }

  //     this.interval = setInterval(async () => {
  //       if(this.count === this._commonService.helpImageList.length ){
  //         this.count = 0;
  //       }
  //       this._commonService.helpImage = this._commonService.helpImageList[this.count].image
  //       this.count++
  //       this.timeOut();
  //     },time)
  //  }
  // getImage() {
  //   this.imageSubscription = this.source.subscribe( async () => {
  //     this._commonService.helpImageList.forEach( (e) => {
  //       if(e.Name == )
  //     })
  // }
  // this.notificationCountSubscription = source.subscribe( async () => {
  //   try {
  //     let countData = await this._apiService.getNotificationsUnreadCount();
  //     localStorage.setItem('notificationsUnreadCount', countData.body.NotificationsUnreadCount ? countData.body.NotificationsUnreadCount : 0);
  //     this.notificationsUnreadCount = countData.body.NotificationsUnreadCount ? countData.body.NotificationsUnreadCount : 0;
  //   } catch (error) {
  //     this.log(error, this.ERROR);
  //   }
  // } )
}
